<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch :checked="details.gifts_after" @toggle="save"></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'GiftsIndirectOption',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    isValidated() {
      return true
    },
    heading() {
      return "Would you like to leave any 'second death' gifts?"
    },
    partner() {
      return this.$store.getters.partner
    },
    subHeading() {
      return (
        'These gifts will pass only if you are the second person to pass away or if you both died together. ' +
        'As an example couples often leave gifts to charity that are payable only once they have both passed away.'
      )
    },
    number() {
      if (this.details.gifts_before) return '4'
      return '3'
    },
    forwardTo() {
      if (this.details.gifts_after) return '/gifts/indirect'
      return '/gifts/check_and_confirm'
    },
    backTo() {
      if (this.details.gifts_before) return '/gifts/direct'
      else return '/gifts/direct_option'
    },
    details() {
      return this.$store.getters.details
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          gifts_after: !this.details.gifts_after
        })
      }
    }
  }
}
</script>

<style></style>
